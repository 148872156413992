/* Container styling */
.container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: transparent;
}

/* Blur background */
.container::before {
    content: "";
    background-image: url('./Mehndi\ Ki\ Raat.jpeg'); /* Replace with your image URL */
    filter: blur(6px); /* Adjust the blur amount as needed */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.container img {
    width: 450px;
    border-radius: 5px 0px 0px 5px;
}

.inner-container {
    background-color: #E79D38  ;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 15px;
}

/* Form container styling */
.form-container {
    background-color: #ffffff;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Image styling */
.event-image {
    max-width: 100%;
    height: auto;
}

/* Form field styling */
.form-label {
    display: block;
    margin-bottom: 5px;
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-input[type="submit"] {
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
}

.form-input[type="submit"]:hover {
    background-color: #0056b3;
}

.form-button {
    width: 100%;
    background-color: #E79D38;
    color: white;
    border-radius: 5px;
}

/* Make Payment button hover style */
.form-button:hover {
    background-color: #794D28;
}


@media (max-width: 746px) {
    .container img {
        width: 400px;
        border-radius: 5px 5px 0px 0px;
    }

    .inner-container {
        display: flex;
        flex-direction: column;
        padding: 7px;
        border-radius: 10px;
    }

    .form-container {
        background-color: #ffffff;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }
}