body.active-modal-payment {
    overflow-y: hidden;
}

.modal-payment, .overlay {
    width: 100vw;
    height: 100vh;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.modal-header-payment {
    background: #f2f2f2;
    padding: 10px 16px;
}

.modal-body-payment {
    background:white;
    padding: 2px 16px;
    padding-top: 2em;
}

.table {
    padding-bottom: 1em;
    text-align: right;
}

.modal-body-payment table {
    border-collapse: collapse;
    width: 100%;
    padding-bottom: 2em;
}
.modal-body-payment th, td {
    color: #454545;
    padding: 8px;
    text-align: left;
}
.modal-body-payment th {
    border-left: 0px;
    background-color: #f2f2f2;
}

.modal-content-payment {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    color: #454545;
}

.image {
    float: left; /* Float the image to the left */
    margin-right: 20px; /* Add space between image and content */
    width: 100px; /* Adjust the width of the image as needed */
    height: auto; /* Maintain aspect ratio */
}
.content {
    overflow: hidden; /* Clear the float */
}

.close-modal-payment {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 5px 7px;
    background-color: transparent;
}

.close-modal-payment:hover {
    position: absolute;
    background-color: transparent;
}

@media (max-width: 746px) {
    .table {
        padding-bottom: 1em;
    }
    
    .table h6 {
        text-align: right;
        font-size: small;
    }

    .modal-content-payment {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: white;
        padding: 14px 28px;
        border-radius: 3px;
        max-width: 600px;
        min-width: 300px;
        color: #454545;
    }

    .modal-payment {
        z-index: 300;
    }
}
