.logo {
    position: fixed;
    top: 10px;
    left: 10px;
    width:fit-content;
    height:fit-content;
    background: transparent;
    z-index: 201;
}
.logo img {
    width:150px;
}
.navigation {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background: #111;
    z-index: 200;
    clip-path: circle(25px at calc(100% - 45px) 45px);
    transition: all 300ms ease-in-out;
}
.navigation.active {
    clip-path:circle(75%);
}
.navigation .ham-btn {
    position:absolute;
    top:20px;
    right:20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
.navigation .ham-btn span {
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    width:50%;
    height:2px;
    background: #f5f5f5;
    transition:all 200ms ease-in-out;
}
.navigation .ham-btn span:nth-child(1) {
    top:30%;
}
.navigation .ham-btn span:nth-child(2) {
    top:50%;
}
.navigation .ham-btn span:nth-child(3) {
    top:70%;
}
.navigation.active .ham-btn span:nth-child(1) {
    top:50%;
    transform:translate(-50%,-50%) rotate(45deg);
}
.navigation.active .ham-btn span:nth-child(2) {
    display: none;
}
.navigation.active .ham-btn span:nth-child(3) {
    top:50%;
    transform:translate(-50%,-50%) rotate(135deg);
}
.navigation .links {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.navigation .links a {
    position:relative;
    display: inline-block;
    margin: 20px 0px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #f5f5f5;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.navigation .links a:after {
    content:"";
    position:absolute;
    left: 0px;
    bottom: -5px;
    height: 2px;
    background: #006600;
}
.navigation .links a:hover:after {
    width: 100%;
}
.navigation .social-container {
    position: absolute;
    top:75%;
    left:50%;
    transform: translate(-50%,-50%);
}


@media (max-width: 746px) {

    .navigation .social-container {
        align-items: center;
        text-align: center;
        max-width: 800px;
        width: 100%;
    }

    .logo img {
        width:100px;
    }

    .navigation {
        position:fixed;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background: #111;
        z-index: 200;
        clip-path: circle(17px at calc(100% - 30px) 32px);
        transition: all 300ms ease-in-out;
    }

    .navigation .ham-btn {
        position:absolute;
        top:15px;
        right:13px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;
    }
}
.navigation .social-container a {
    position:relative;
    display: inline-block;
    margin: 20px 20px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #f5f5f5;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.navigation .social-container .social:hover {
    transform: translateY(-2px);
}
.navigation .social-container .facebook:hover {
    color: #4267B2;
}
.navigation .social-container .whatsapp:hover {
    color: #25D366;
}
.navigation .social-container .instagram:hover {
    color: #833AB4;
}
.navigation .social-container .tiktok:hover {
    color:#EE1D52;
}