body.active-modal-checkin {
    overflow-y: hidden;
}

.modal-checkin, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.modal-header {
    background: #007417;
    padding: 10px 16px;
}

.modal-body {
    background:#1e8f31;
    padding: 2px 16px;
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #1e8f31;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    color: #Feffed;
}

.modal-header-failed {
    background: #9E1711;
    padding: 10px 16px;
}

.modal-body-failed {
    background:#B12E21;
    padding: 2px 16px;
}

.modal-content-failed {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #B12E21;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    color: #Feffed;
}

.checkin-modal {
    padding: 5px 7px;
}

.close-modal-failed {
    background-color: #9E1711;
    padding: 5px 7px;
}

.close-modal {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 5px 7px;
    background-color: transparent;
}

.modal-header-manual-checkin {
    background: #a9a9a9;
    padding: 10px 16px;
    text-align: left;
}

.modal-body-manual-checkin {
    background:#D3D3D3;
    padding: 2px 16px;
    text-align: left;
}

.modal-content-manual-checkin {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #D3D3D3;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    color: black;
}

.modal-content-buttons {
    display: flex;
    float:right;
    text-align: right;
    
}

.modal-exit {
    background: red;
    padding: 5px 7px;
}
