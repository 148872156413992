.container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

html {
  color: #151c1f;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;

  background: #ffffff;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Helvetica Neue";
}

h1 {
  text-align: center;
  font-size: 150px;
}

form {
  width: 100%;
  margin: 0 auto;
}

button {
  font-family: "Helvetica Neue";
  margin: 0 auto 20px auto;
  background-color: #3374ff;
  padding: 12px 16px;
  color: #fff;
  border-radius: 8px;
  border: none;
}

button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
  filter: brightness(90%);
  -webkit-transition: all 30ms ease-in-out;
  -moz-transition: all 30ms ease-in-out;
  -ms-transition: all 30ms ease-in-out;
  -o-transition: all 30ms ease-in-out;
  transition: all 30ms ease-in-out;
}

input:focus {
  border: 2px solid rgb(0, 106, 255);
  outline: none;
}

input {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background-color: #fff;
  font-size: 16px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
